.contactCart{ 
border: 1px solid;
width: 270px;
height: 220px;
padding: 25px;
border-radius: 25px;
background:#FBFFE8 ; 
}
.contactCart:hover{
    cursor: pointer;
    background-color: #B0CB1F;
}
.cSocialIcon{
    width: 80px;
    height: 80px;
    flex-shrink: 0;
    border-radius: 69px;
    background: #B0CB1F;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center; 
    border: 1px solid #FBFFE8;
    }
    .cSocialIcon:hover{ 
        background: #FBFFE8;
    }
    .iconFont{
        font-size: 30px;
        rotate: 0deg;
        transition-duration: 200ms;
    }
    .cSocialIcon:hover .iconFont{
        /* rotate: 45deg; */
        transition-duration: 200ms;
    }
    .cercleSoil{
        position: absolute;
        /* top: 0; */
        right: -50%;
    }
    @media(max-width:575px){
        .cercleSoil{
            position: relative; 
            left:0;
            /* margin-top: auto; */
        }
    }