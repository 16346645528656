.circle{
    position: relative;
    width:600px;
    height:600px;
 
    border-radius:50%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.2s ease-in-out;
}
.logo{
    position: absolute;
    width:500px;
    height:500px;
    border-radius:50%; 
    border: 1px solid #8EA802 ;
    display: flex;
    align-items: center;
    /* justify-content: center; */
}
.logo p{
    margin-left: 15%;
}
.text{
    position:absolute;
    width: 100%;
    height: 100%; 
    display:flex;
}
.text span{
    position: absolute;
    left: 50%;
    transform-origin: 0 300px; 
    transition: transform 1s ease; 
    cursor: pointer;
    color: #8EA802; 
    font-weight: 500;
    font-size: 20px;

}
.text span:hover{
    font-weight:900;
   color:black;
}

@media (max-width:767px){
    .circle{
        position: relative;
        width:500px;
        height:500px; 
        border-radius:50%;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: 0.2s ease-in-out;
        margin: auto;
        margin-top: -40%;
    }
    .logo{
        position: absolute;
        width:400px;
        height:400px;
        border-radius:50%; 
        border: 1px solid #8EA802 ;
        display: flex;
        align-items: end;
        justify-content: center;
    }
    .logo p{
        margin-left: 0;
        margin-bottom: 10%;
    }
    .text{
        position:absolute;
        width: 100%;
        height: 100%; 
        display:flex;
    }
    .text span{
        position: absolute;
        left: 50%;
        transform-origin: 0 250px; 
        transition: transform 1s ease; 
        cursor: pointer;
        color: #8EA802; 
    }
}

@media (max-width:650px){
    .circle{
        position: relative;
        width:320px;
        height:320px; 
        border-radius:50%;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: 0.2s ease-in-out;
        margin: auto;
        margin-top: -40%;
    }
    .logo{
        position: absolute;
        width:220px;
        height:220px;
        border-radius:50%; 
        border: 1px solid #8EA802 ;
    }
    .text{
        position:absolute;
        width: 100%;
        height: 100%; 
        display:flex;
    }
    .text span{
        position: absolute;
        left: 50%;
        transform-origin: 0 160px; 
        transition: transform 1s ease; 
        cursor: pointer;
        color: #8EA802; 
    }
}