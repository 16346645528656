.aboutValueMain {
position: relative;
height: 80vh;
}
.aboutValueMain > div{ 
    display: flex;
    align-items: center;
    justify-content: center;
}

.aboutValue1{
    width: 204px;
    height: 204px;
    background: #fff;
    color: #0074DB; 
    position: absolute;
}
.aboutValue2{
    width: 168px;
    height: 168px;
    background: #D1F123;
    color: #0074DB; 
    position: absolute;
    left: 30%;
}
.aboutValue3{
    width: 141px;
    height: 141px;
    background: #41A6FF;
    color: #FFF; 
    position: absolute;
    left: 45%;
    top: 10%;
}
.aboutValue4{
    width: 219px;
    height: 219px;
    background: #D1F123;
    color: #0074DB; 
    position: absolute;
    left: 60%;
}
.aboutValue5{
    width: 183px;
    height: 183px;
    background: #fff;
    color: #0074DB; 
    position: absolute;
    left: 80%;
    top: 20%;
}
.aboutValue6{
    width: 131px;
    height: 131px;
    background: #41A6FF;
    color: #FFF; 
    position: absolute;
    left: 5%;
    top: 45%;
}
.aboutValue7{
    width: 167px;
    height: 167px;
    background: #D1F123;
    color: #0074DB; 
    position: absolute;
    left: 18%;
    top: 30%;
}
.aboutValue8{
    width: 219px;
    height: 219px;
    background: #fff;
    color: #0074DB; 
    position: absolute;
    left: 35%;
    top: 40%;
}
.aboutValue9{
    width: 167px;
    height: 167px;
    background: #D1F123;
    color: #0074DB; 
    position: absolute;
    left: 55%;
    top: 40%;
}
.aboutValue10{
    width: 141px;
    height: 141px;
    background: #41A6FF;
    color: #FFF; 
    position: absolute;
    left: 70%;
    top: 50%;
}

@media (max-width:990px){
    .aboutValue1{
        width: 204px;
        height: 204px;
        background: #fff;
        color: #0074DB; 
        position: absolute;
    }
    .aboutValue2{
        width: 168px;
        height: 168px;
        background: #D1F123;
        color: #0074DB; 
        position: absolute;
        left: 30%;
    }
    .aboutValue3{
        width: 141px;
        height: 141px;
        background: #41A6FF;
        color: #FFF; 
        position: absolute;
        left: 55%;
        top: 10%;
    }
    .aboutValue4{
        width: 200px;
        height: 200px;
        background: #D1F123;
        color: #0074DB; 
        position: absolute;
        left: 75%;
    }
    .aboutValue5{
        width: 153px;
        height: 153px;
        background: #fff;
        color: #0074DB; 
        position: absolute;
        left:60%;
        top: 65%;
    }
    .aboutValue6{
        width: 131px;
        height: 131px;
        background: #41A6FF;
        color: #FFF; 
        position: absolute;
        left: 5%;
        top: 45%;
    }
    .aboutValue7{
        width: 150px;
        height: 150px;
        background: #D1F123;
        color: #0074DB; 
        position: absolute;
        left: 20%;
        top: 30%;
    }
    .aboutValue8{
        width: 200px;
        height: 200px;
        background: #fff;
        color: #0074DB; 
        position: absolute;
        left: 40%;
        top: 35%;
    }
    .aboutValue9{
        width: 167px;
        height: 167px;
        background: #D1F123;
        color: #0074DB; 
        position: absolute;
        left: 20%;
        top: 60%;
    }
    .aboutValue10{
        width: 141px;
        height: 141px;
        background: #41A6FF;
        color: #FFF; 
        position: absolute;
        left: 68%;
        top: 40%;
    }
}

@media (max-width:790px){
    .aboutValue1{
        width: 150px;
        height: 150px;
        background: #fff;
        color: #0074DB; 
        position: absolute;
    }
    .aboutValue2{
        width: 110px;
        height: 110px;
        background: #D1F123;
        color: #0074DB; 
        position: absolute;
        left: 30%;
    }
    .aboutValue3{
        width: 100px;
        height: 100px;
        background: #41A6FF;
        color: #FFF; 
        position: absolute;
        left: 55%;
        top: 10%;
    }
    .aboutValue4{
        width: 150px;
        height: 150px;
        background: #D1F123;
        color: #0074DB; 
        position: absolute;
        left: 75%;
    }
    .aboutValue5{
        width: 120px;
        height: 120px;
        background: #fff;
        color: #0074DB; 
        position: absolute;
        left:60%;
        top: 65%;
    }
    .aboutValue6{
        width: 90px;
        height: 90px;
        background: #41A6FF;
        color: #FFF; 
        position: absolute;
        left: 5%;
        top: 45%;
    }
    .aboutValue7{
        width: 100px;
        height: 100px;
        background: #D1F123;
        color: #0074DB; 
        position: absolute;
        left: 20%;
        top: 30%;
    }
    .aboutValue8{
        width: 150px;
        height: 150px;
        background: #fff;
        color: #0074DB; 
        position: absolute;
        left: 40%;
        top: 35%;
    }
    .aboutValue9{
        width: 130px;
        height: 130px;
        background: #D1F123;
        color: #0074DB; 
        position: absolute;
        left: 20%;
        top: 60%;
    }
    .aboutValue10{
        width: 100px;
        height: 100px;
        background: #41A6FF;
        color: #FFF; 
        position: absolute;
        left: 68%;
        top: 40%;
    }
}

@media (max-width:590px){
    .aboutValue1{
        width: 150px;
        height: 150px;
        background: #fff;
        color: #0074DB; 
        position: absolute;
    }
    .aboutValue2{
        width: 110px;
        height: 110px;
        background: #D1F123;
        color: #0074DB; 
        position: absolute;
        left: 50%;
        top: -5%;
    }
    .aboutValue3{
        width: 100px;
        height: 100px;
        background: #41A6FF;
        color: #FFF; 
        position: absolute;
        left: 70%;
        top: 15%;
    }
    .aboutValue4{
        width: 120px;
        height: 120px;
        background: #D1F123;
        color: #0074DB; 
        position: absolute;
        left: 30%;
        top: 25%;
    }
    .aboutValue5{
        width: 130px;
        height: 130px;
        background: #fff;
        color: #0074DB; 
        position: absolute;
        left:5%;
        top: 45%;
    }
    .aboutValue6{
        width: 90px;
        height: 90px;
        background: #41A6FF;
        color: #FFF; 
        position: absolute;
        left: 0%;
        top: 28%;
    }
    .aboutValue7{
        width: 110px;
        height: 110px;
        background: #D1F123;
        color: #0074DB; 
        position: absolute;
        left: 40%;
        top: 60%;
    }
    .aboutValue8{
        width: 130px;
        height: 130px;
        background: #fff;
        color: #0074DB; 
        position: absolute;
        left: 60%;
        top: 40%;
    }
    .aboutValue9{
        width: 130px;
        height: 130px;
        background: #D1F123;
        color: #0074DB; 
        position: absolute;
        left: 0%;
        top: 75%;
    }
    .aboutValue10{
        width: 100px;
        height: 100px;
        background: #41A6FF;
        color: #FFF; 
        position: absolute;
        left: 60%;
        top: 80%;
    }
}