.socialIcon{
width: 69px;
height: 69px;
flex-shrink: 0;
border-radius: 69px;
background: white;
cursor: pointer;
display: flex;
align-items: center;
justify-content: center;
}
.socialIcon:hover{ 
    background: #B0CB1F;
}
.iconFont{
    rotate: 45deg;
    transition-duration: 200ms;
}
.socialIcon:hover .iconFont{
    rotate: 0deg;
    transition-duration: 200ms;
}