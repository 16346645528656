.productMobile{
    display: none;
 }
.productPc{
    position: relative;
    display: block;
 }
 .sticky-parent-soil{
    height:300vh;
  }
  .sticky-soil{
    position: sticky;
    top: 0px;
    max-height: 100vh;
    overflow-x: hidden;
    overflow-y: hidden;
  }
  .product{
    display: flex;
    min-width:  35vw;
    height: 100vh;
    align-items: center;
    justify-content: space-around;
  }
  
  .horizontal-soil{
    display: flex;
  }
  .productCartSoil{
    height: 35vh;
    transition: .2s linear;
  }
  .productCartSoilButton{
    display: none;
  }

  .productCartMainSoil:hover .productCartSoil{
    height: 45vh;
  }
  .productCartMainSoil:hover .productCartSoilButton{
    display: block;
  }

 
@media(max-width:790px){ 
    .productPc{
       display: none;
    }
    .productMobile{
       display: block;
    }
     
 }