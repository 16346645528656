.navbarMain{ 
    background-color: #FBFFE8;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 2; 
    height: 70px;   
    transition: 300ms;
    display:flex;
    align-items: center; 
}
.navbarMain--hidden{
    transform: translateY(-70px);
    transition: 2000ms; 
}
.navPc{
    display: block;
}
.navMb{
    display: none;
}
#menu{
    width:35px;
    height: 30px;
    cursor: pointer;
    z-index: 100;
}
.bar{
    height: 5px;
    width: 100%;
    background-color: black;
    display: block;
    border-radius: 5px;
    transition: 0.7s ease;
}
#bar1{
    transform: translateY(-4px);
}
#bar3{
    transform: translateY(4px);
}
.icon .bar{
    background-color: white ; 
}
.icon #bar1{
    transform: translateY(4px) rotate(-135deg);
}
.icon #bar3{
    transform: translateY(-7px) rotate(135deg);
}
.icon #bar2{
   opacity: 0;
}
#nav{
    position: fixed;
    top: 0;
    right: 0; 
    background-color: black; 
    background-color: black; 
    padding: 50px;
    height: 110vh;
    z-index: -100;
 
}
 
   
  

@media(max-width:990px){
    .navPc{
        display: none;
    }
    .navMb{
        display: block;
    }
}