.animalOption{
    gap: 30px;
  }
  .animalIcon{
     background: #BFC5A0 ;
     width: 80px;
     height: 80px;
     display: flex;
     justify-content: center;
     align-items: center;
  }
  .animalIcon img{
     width: 50px;
  }
  .animalText{
     color: #BFC5A0 ;
  }
  .animal:hover .animalIcon{
     background: black;
  }
  .animal:hover .animalText{
     color: black;
     font-weight: 600;
  } 

  .animalCapDiv { 
    width: 270px;
    margin: 15px auto;
    height: 270px;
    /* background: #B0CB1F; */
    /* opacity: 0.7; */
    border-radius:100%;  
 }
 .animalCap:hover  .animalCapDiv { 
    width: 270px;
    margin: 15px auto;
    height: 270px;
    background: #B0CB1F;
    opacity: 0.7;
    border-radius: 100%;  
    transition: 1s;
 }
 
  .animalCap:hover .cardText{
     color: black;
 }
 .vetminPro{
   width: 70px;
   height: 70px;
   border-radius: 50%;
   background: #D1F123;
   margin: auto;
   display: flex;
   align-items: center;
   justify-content: center;

 }
 .vetminName{
   font-size: 20px;
 }
 
 
@media(max-width:790px){
    .animalOption{
        gap: 10px;
      }
}

@media(max-width:590px){
   .vetminPro{
      width: 50px;
      height: 50px;
      border-radius: 50%;
      background: #D1F123;
      margin: auto; 
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .vetminName{
      font-size: 10px;
    }
}