.vetminTop{
   height: 100vh;
}
.aboutBg{
    background:#B0CB1F;
    clip-path: circle(75.0% at 6% 50%)
}
.about1{
    margin-left: 5%;
 }
 .aboutHide{
   height: 250px;
 }
 .about2{
   position: absolute;
   bottom: 0;
 }
 .about{
    /* background: url('./images/about.png'); */
    background-repeat:'no-repeat' !important;
    background-size: cover;
    /* background-attachment: fixed; */
  background-position: right top;
 }
 .aboutUsButtom{
   margin: 50px;
   text-align: justify;
 }
 .weCareOption{
   gap: 30px;
 }
 .weCareIcon{
    background: #0074DB ;
    width: 80px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
 }
 .weCareIcon img{
    width: 50px;
 }
 .weCareText{
    color: #69B9FF ;
 }
 .weCare:hover .weCareIcon{
    background: black;
 }
 .weCare:hover .weCareText{
    color: white;
 }

 .cardNo svg{
    fill: none;
    stroke:white ;
 }
 .cardNo{ 
    color:white ;
 }
 .cardText{
color: white;
 }
 .weCareMobile{
   display: none;
}
.weCarePc{
   position: relative;
   display: block;
}
.sticky-parent{
   height:250vh;
 }
 .sticky{
   position: sticky;
   top: 0px;
   max-height: 100vh;
   overflow-x: hidden;
   overflow-y: hidden;
 }
 .dim{
   display: flex;
   min-width:  35vw;
   height: 100vh;
   align-items: center;
   justify-content: space-around;
 }
 
 .horizontal{
   display: flex;
 }


 .ourCapDiv { 
   width: 90%;
   margin: 20px auto;
   height: 325px;
   /* background: #B0CB1F; */
   /* opacity: 0.7; */
   border-radius: 43%;  
}

.ourCap:hover  .ourCapDiv { 
   width: 90%;
   /* margin: auto; */
   height: 325px;
   background: #B0CB1F;
   opacity: 0.7;
   border-radius: 43%;  
   transition: 1s;
}
 .ourCap:hover .cardNo svg{
    stroke:black;
 }
 .ourCap:hover .cardText{
    color: black;
}
.ourCap:hover .cardNo{
color: black;
    }

.testimonials{
   background: linear-gradient(#fff 50%,#000000 0);
}
.testimonialsCard{
   position: relative; 
   margin: auto;
   display: flex;
width: 300px;
/* padding: 15px 18.75px 16px 82.5px; */
padding: 15px 18.75px 16px 25.5px;

flex-direction: column;
justify-content: center;
align-items: flex-start;
gap: 13.125px; 
border-radius: 41.25px 12px 12px 12px;
background: #FFF;
box-shadow: 15px 21.700050354003906px 0px 0px #0081F3; 
}
 .testimonialsPost{
color: #505050;
font-size: 10px;
font-family: Manrope;
font-style: normal;
font-weight: 400;
line-height: normal;
 }
.testimonialsImg{
   width: 113.625px;
height: 113.625px;
position: absolute;
left: -45.375px;
bottom: 0.533px;
border-radius: 113.625px;
}
 

.testimonials .react-multi-carousel-list  .react-multi-carousel-track{
   align-items: end;
}
.react-multiple-carousel__arrow--left{
   z-index: 0 !important;
}
.react-multiple-carousel__arrow--right{
   z-index: 0 !important;
} 
 
.blackBtn{ 
  position: relative; 
  width: fit-content;
  border-radius: 50px;
  cursor: pointer;
  font-weight: bold;

}
 
.blackText{
  padding: 15px 30px; 
  border-radius: 50px;  
  border: 3px solid black; 
}
.blackBg{
   border: 3px solid transparent; 
  border-radius: 50px; 
   padding: 15px 30px;
   position: absolute;
   top: 0;
   background: black;
   color: white;
   clip-path: circle(120% at 50% 50%); 
   transition: clip-path .6s ease-out !important; 
}
.blackBtn:hover .blackBg{  
    background: black;  
    color: white;
    clip-path: circle(0% at 50% 50%);  
 }
 .blackBtn:hover {
   animation: a .4s 1 linear;
 }  
 .whiteBtn{ 
   position: relative; 
   width: fit-content;
   border-radius: 50px;
   cursor: pointer;
   font-weight: bold;
 }
 
 .whiteText{
   padding: 15px 30px; 
  border-radius: 50px;   
  color: white;
  border: 3px solid white;
}
.whiteBg{
  border-radius: 50px; 
   padding: 15px 30px;
   position: absolute;
   top: 0;
   background: white;
   color: black;
   clip-path: circle(120% at 50% 50%); 
   transition: clip-path .6s ease-out !important; 
  border: 3px solid white;

}

.whiteBtn:hover .whiteBg{  
    background: white;
    color: white;
    clip-path: circle(0% at 50% 50%);  
 }
 .whiteBtn:hover {
   animation: a .4s 1 linear !important;
 }

 .blueBtn{ 
   position: relative; 
   width: fit-content;
   border-radius: 50px;
   cursor: pointer;
   font-weight: bold;
 }
 
 .blueText{
   padding: 15px 30px; 
  border-radius: 50px;   
  color: black;
  border: 3px solid #0081F3;
  /* background: white; */
}
.blueBg{
  border-radius: 50px; 
   padding: 15px 30px;
   position: absolute;
   top: 0;
   background: #0081F3;
   color: white;
   clip-path: circle(120% at 50% 50%); 
   transition: clip-path .6s ease-out !important; 
  border: 3px solid #0081F3;

}

.blueBtn:hover .blueBg{  
    /* background: white; */
    color: white;
    clip-path: circle(0% at 50% 50%);  
 }
 .blueBtn:hover {
   animation: a .4s 1 linear !important;
 }


 @keyframes a {
   0% {
     transform: scale(0.7,1);
   }  
   50% {
     transform: scale(1.3, 1);
   }
 
   100% {
     transform: scale(1,1);
   }
 }
 .crown{
   left: 10%;
 }
 @media(max-width:1090px){
   .about{
        background:#B0CB1F ;
    }
    .dim{
     min-width: 50vw !important;
    }
}
 @media(max-width:990px){
    .about{
         background:#B0CB1F ;
     }
    
}
@media(max-width:790px){
   .vetminTop{
      height: auto;
   }
   .aboutBg{
      background:#B0CB1F;
      clip-path: inset(0 0 0 0);

  }
   .weCareOption{
      gap: 10px;
    }
   .weCarePc{
      display: none;
   }
   .weCareMobile{
      display: block;
   }
    
}
 
@media(max-width:690px){
   .aboutHide{
       height: 50px;
   }
    .about2{
         background:#D2EA53 ;
         position: relative;
         bottom: 0;
     }
     .aboutUsButtom{
      margin:25px 15px;
      text-align: justify;
    }
    .crown{
      left: 60%;
    }
}