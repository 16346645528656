.App {
  text-align: center; 
}

@media(max-width:790px){
  .App { 
    overflow-x: hidden;
  } 
}
a{
  color: black;
}


 